/*@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700,800,900&display=swap');*/
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './styles/lightbox-style.css';
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/NunitoSans-Regular.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/NunitoSans-Italic.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/NunitoSans-Bold.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 700;
    src: url('fonts/NunitoSans-BoldItalic.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    src: url('fonts/NunitoSans-ExtraBold.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 800;
    src: url('fonts/NunitoSans-ExtraBoldItalic.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/NunitoSans-Black.ttf')  format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 900;
    src: url('fonts/NunitoSans-BlackItalic.ttf')  format('truetype');
}

body {
    margin: 0;
    padding: 0;
    font-family: "Nunito Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    display: flex;
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a:hover {
    text-decoration: none;
}

.warningBox {
    background-color: rgba(233, 227, 113, 1);
    padding: 20px;
}

.dottedList > li {
    color: rgba(255, 159, 0, 1);
    list-style-type: none;
    position: relative; /* It is required for setting position to absolute in the next rule. */
}

.dottedList > li:not(:first-child) {
    margin-top: 15px;
}

.dottedList > li::before {
    content: '\2022'; /* Unicode for • character */
    position: absolute;
    left: -0.8em; /* Adjust this value so that it appears where you want. */
    top: 2px;
    font-size: 36px; /* Adjust this value so that it appears what size you want. */
}

.bracketedList {
    counter-reset: list;
}

.bracketedList > li {
    list-style: none;
    position: relative; /* It is required for setting position to absolute in the next rule. */
}

.bracketedList > li::before {
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
    position: absolute;
    left: -20px; /* Adjust this value so that it appears where you want. */
}

.ol-mouse-position {
    color: white;
    font-weight: bold;
    right: 70px;
}

.ol-zoom {
    top: initial;
    left: initial;
    right: .5em;
    bottom: .5em;
}

.tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}

.tooltip-measure:before,
.tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.tooltip-static:before {
    border-top-color: #ffcc33;
}

.ol-control.ol-layerswitcher {
    top: 10px;
}

.ol-control.ol-layerswitcher-popup {
    bottom: initial;
    left: initial;
    right: .5em;
    top: 8px;
}

.draw-polygon {
    right: .5em;
    bottom: 80px;
}

.ol-touch .draw-polygon {
    bottom: 80px;

}

.layers {
    right: .5em;
    top: 30px;
}

.ol-touch .layers {
    bottom: 30px;
}

.ol-zoom-in:focus {

}

.ol-button i {
    color: inherit;
}

.ol-button.ol-active button {
    background: rgba(60, 136, 0, 0.7)

}

.info-header {
    font-size: 18px;
    font-weight: 900;
}